import React from "react"
import { Button, ButtonLink, AnFadeIn, ArticleLayout } from "components"
import { graphql, navigate } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import { RichText } from "prismic-reactjs"
import moment from "moment"
import cx from "classnames"
import {
  articleHeader,
  articleHeaderLeft,
  articleHeaderRight,
  articleHeaderRightNavy,
  articleHeaderRightBlue,
  articleHeaderRightRed,
} from "./styles.module.css"
import {
  articleMain,
  mediaEl,
  gridEl,
  gridImageRight,
  articleBottom,
  articleFooterImage,
  articleFooterButton,
  centeredText,
  inArticleButton,
  textBlock,
  gridElCenter,
  blueText,
  navyText,
  freeTextArea,
} from "./articleStyles.module.css"
import Imgix from "react-imgix"
import { Helmet } from "react-helmet"

const Article = ({ data }) => {
  const {
    client_name,
    article_date,
    image_bottom,
    link_to_project,
    subtitle,
    title,
    image_top,
    top_colour,
    main_body,
    two_col,
    two_col_content,
  } = data.prismicArticle.data

  if (two_col) {
    return (
      <ArticleLayout
        title={title.text}
        subtitle={subtitle.text}
        leftContent={two_col_content[0].content_left.raw}
        rightContent={two_col_content[0].content_right.raw}
        bottomContent={two_col_content[0].content_bottom.raw}
      />
    )
  }
  return (
    <AnFadeIn>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Still Curious | Journal ${client_name.text}`}</title>
      </Helmet>
      <article>
        <header className={articleHeader}>
          <div
            className={articleHeaderLeft}
            style={{ backgroundImage: `url(${image_top.url})` }}
          ></div>
          <div
            className={cx(articleHeaderRight, {
              [articleHeaderRightNavy]: top_colour === "Navy",
              [articleHeaderRightBlue]: top_colour === "Light Blue",
              [articleHeaderRightRed]: top_colour === "Red",
            })}
          >
            <div className={top_colour === "Light Blue" ? navyText : ""}>
              <h1>{title.text}</h1>
              <h2>{subtitle.text}</h2>
              {article_date && (
                <h5>{moment(article_date).format("Do MMMM YYYY")}</h5>
              )}
            </div>
          </div>
        </header>
        <section className={articleMain}>
          {main_body.map((el, i) => (
            <section key={`${i}-article-body`}>
              {/* Free text */}
              {el.free_text_field.raw && (
                <section className={freeTextArea}>
                  <RichText render={el.free_text_field.raw} />
                </section>
              )}
              {/* Media Section */}
              {el.media.url && (
                <video playsInline autoPlay muted className={mediaEl} loop>
                  <source src={el.media.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {/* Top Text */}
              {el.top_text_centered.raw && (
                <div
                  className={cx(
                    textBlock,
                    centeredText,
                    el.blue_text === "blue" ?? blueText
                  )}
                >
                  <RichText render={el.top_text_centered.raw} />
                </div>
              )}
              {/* Text & Image */}
              {(el.main_text.raw.length > 1 || el.image.url) && (
                <div
                  className={cx(gridEl, el.blue_text === "blue" ?? blueText, {
                    [gridImageRight]: el.image_order === "Image Right",
                  })}
                >
                  {el.image.url ? <img src={el.image.url} /> : <img src="" />}
                  <div className={gridElCenter}>
                    <RichText render={el.main_text.raw} />
                    {el.project_link.url && (
                      <div className={inArticleButton}>
                        <ButtonLink
                          title={`More about ${client_name.text}`}
                          target="blank"
                          to={el.project_link.url}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* Bottom Tet */}
              {el.bottom_text.raw.length > 0 && (
                <div
                  className={cx(
                    textBlock,
                    centeredText,
                    el.blue_text === "blue" ?? blueText
                  )}
                >
                  <RichText render={el.bottom_text.raw} />
                </div>
              )}
            </section>
          ))}
        </section>

        <footer>
          {link_to_project.url && (
            <section className={articleFooterButton}>
              <ButtonLink
                title={`More about ${client_name.text}`}
                target="blank"
                to={link_to_project.url}
              />
            </section>
          )}
          {image_bottom.url && (
            <img className={articleFooterImage} src={image_bottom.url} />
          )}
        </footer>
      </article>
      <section className={articleBottom}>
        <Button
          navy
          title="More SC News"
          onClick={() => navigate("/journal")}
        />
      </section>
    </AnFadeIn>
  )
}

export const query = graphql`
  query SingleArticleQuery($uid: String) {
    prismicArticle(uid: { eq: $uid }) {
      _previewable
      data {
        article_date
        title {
          text
        }
        subtitle {
          text
        }
        link_to_project {
          url
        }
        image_bottom {
          alt
          url
        }
        image_top {
          url
        }
        top_colour
        client_name {
          text
        }
        main_body {
          text_colour
          media {
            url
          }
          bottom_text {
            raw
          }
          image_order
          image {
            url
          }
          main_text {
            raw
          }
          project_link {
            url
          }
          top_text_centered {
            raw
          }
          free_text_field {
            raw
          }
        }
        two_col
        two_col_content {
          content_bottom {
            raw
          }
          content_left {
            raw
          }
          content_right {
            raw
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Article, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
