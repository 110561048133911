// extracted by mini-css-extract-plugin
export var articleMain = "articleStyles-module--articleMain--2PZ7Q";
export var mediaEl = "articleStyles-module--mediaEl--1aLUi";
export var gridEl = "articleStyles-module--gridEl--3LxWZ";
export var imgPlaceholder = "articleStyles-module--imgPlaceholder--2jMPQ";
export var textBlock = "articleStyles-module--textBlock--2a2Lu";
export var centeredText = "articleStyles-module--centeredText--2oaoj";
export var articleFooterButton = "articleStyles-module--articleFooterButton--O8mvo";
export var articleFooterImage = "articleStyles-module--articleFooterImage--24nE0";
export var articleBottom = "articleStyles-module--articleBottom--1E8jv";
export var gridImageRight = "articleStyles-module--gridImageRight--32pWD";
export var inArticleButton = "articleStyles-module--inArticleButton--5Ttzj";
export var blueText = "articleStyles-module--blueText--2GpMi";
export var gridElCenter = "articleStyles-module--gridElCenter--RFMO8";
export var navyText = "articleStyles-module--navyText--3rZHi";
export var freeTextArea = "articleStyles-module--freeTextArea--3Ct12";